'use client';

import Box from '@mui/material/Box';

import { usePathname } from 'next/navigation';
import { useBoolean } from '../../hooks/use-boolean';
import { useResponsive } from '../../hooks/use-responsive';
import { useSettingsContext } from '../../settings';

import Main from './main';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';
import Header from './header';
import { paths } from '@/app/utilities/consts';

export default function AdminLayout({ children }) {
  const settings = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini />;

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;

  const pathname = usePathname();

  if (
    pathname !== paths.home &&
    pathname !== paths.devices &&
    pathname !== paths.orders &&
    pathname !== paths.resellers &&
    pathname !== paths.credits &&
    pathname !== paths.appSettings &&
    pathname !== paths.userSettings &&
    pathname !== paths.languageSettings &&
    !pathname.includes(paths.devices)
  ) {
    return <>{children}</>;
  }

  if (isMini) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
          }}
        >
          {lgUp ? renderNavMini : renderNavVertical}

          <Main>{children}</Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        {renderNavVertical}

        <Main>{children}</Main>
      </Box>
    </>
  );
}
