/* eslint-disable import/no-cycle */
import NavList from './nav-list';

export default function NavSubList({ data, depth, slotProps }) {
  return (
    <>
      {data.map(list => (
        <NavList key={list.title} data={list} depth={depth + 1} slotProps={slotProps} />
      ))}
    </>
  );
}
