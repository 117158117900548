import { useEffect } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';

import { usePathname } from 'next/navigation';
import Scrollbar from '../../scrollbar';

import Logo from '../../logo';
import { useResponsive } from '../../hooks/use-responsive';

import { NAV } from '@/app/utilities/consts';
import { adminNavData } from './config-navigation';
import NavToggleButton from '../../navigation/vertical/nav-toggle-button';
import NavSectionVertical from '../../navigation/vertical/nav-section-vertical';
import { useUserContext } from '../../contexts/user-context';

export default function NavVertical({ openNav, onCloseNav }) {
  const pathname = usePathname();
  const { user } = useUserContext();

  const lgUp = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const adminNav = adminNavData.map(section => ({
    subheader: section.subheader,
    items: section.items.filter(
      item => user.type === 'super-admin' || (user.type === 'reseller' && item.reseller)
    ),
  }));

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Logo sx={{ mt: 0, ml: 0, mb: 1 }} />

      <NavSectionVertical data={adminNav} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL },
      }}
    >
      <NavToggleButton />

      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            borderRight: theme => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
